$(document).ready(function()
{
    var Brickz = window.Brickz || {};
    var $document = $(document);
    var customers = $('#customers');
    var logos = customers.find('img');
    var rows = $('.customers .customer');

    Brickz.activateCustomer = function(customer)
    {
        var logo = $('[data-target=' + customer + ']');

        logos.removeClass('active listening');
        rows.removeClass('active');

        logo.addClass('active');
        $('.customers #' + customer).addClass('active');

        $document.scrollTop(customers.offset().top);
    };

    logos.on('click', function(e)
    {
        var customer = $(this).data('target');

        Brickz.activateCustomer(customer);
    });

    $('.close-customers').on('click', function(e)
    {
        logos.removeClass('active');
        rows.removeClass('active');
        logos.addClass('listening')
    });

    $('#customers-btn').on('click', function(e)
    {
        Brickz.activateCustomer('rabobank');
    });
});