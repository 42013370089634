$(document).ready(function () {

    $('.ajax-form').on('submit', function (e) {
        e.preventDefault();

        var form = $(this);
        var submitBtn = form.find('[type=submit]');

        submitBtn.val(submitBtn.data('processing'));
        form.find('input, textarea').removeClass('error');
        form.find('.error-box').hide();
        form.find('.success-box').hide();

        $.ajax({
            type: form.prop('method'),
            url: form.prop('action'),
            data: form.serialize(),
            success: function (data) {
                handleSuccess(form, submitBtn, data);
            },
            error: function (data) {
                handleErrors(form, submitBtn, data);
            }
        });
    });

    function handleSuccess(form, submitBtn, data) {
        form.find('.success-box').show();
        form.find('input[type=text], textarea').val('');

        submitBtn.val(submitBtn.data('original'));
    }

    function handleErrors(form, submitBtn, data) {
        var errors = data.responseJSON;
        var errorBox = form.find('.error-box').show();
        var errorBoxList = errorBox.find('ul').html('');

        submitBtn.val(submitBtn.data('original'));

        for ( var error in errors ) {
            form.find('[name=' + error + ']').addClass('error');

            $('<li></li>').text(errors[error]).appendTo(errorBoxList);
        }
    }

});