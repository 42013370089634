$(document).ready(function ()
{
    var Brickz = window.Brickz || {};

    var row = $('#' + Brickz.redirect);

    if ( row.length <= 0 ) return;

    $(document).scrollTop( row.offset().top );

    if ( Brickz.redirect === 'customers' )
    {
        Brickz.activateCustomer('rabobank');
    }

});